import React, { useEffect, useState } from "react";

import { Row } from "reactstrap";

import "./winkel-modal.scss";
/**
 * winkelModal is a modal component that can be used to display a modal with a title, message and a submit button.
 * @param {boolean} show - Should the modal be shown
 * @param {function} onClose - close modal
 * @param {function} onSubmit - submit modal
 * @param {string} title - title of modal
 * @param {string} message - message of modal
 *  @param {string} submitText - text of submit button
 * @param {string} blockedIcon - icon of blocked button
 * @param {string} submitIcon - icon of submit button
 * @param {boolean} secure - is the modal secure
 * @param {string} secure_phrase - secure phrase
 * @param {boolean} textInput - should the modal have a text input
 * @param {string} textPlaceholder - placeholder of text input
 * @param {string} initialText - initial text of text input
 * @param {string} regex - regex for text input
 * @param {ReactNode} children - children of modal
 * @param {string} secondaryText - text of secondary button
 * @param {string} secondaryIcon - icon of secondary button
 * @param {function} secondaryOnSubmit - function of secondary button
 * @param {boolean} showCloseButton - should the close button be shown
 *
 *
 * @returns
 */
const WinkelModal = ({
  show,
  onClose,
  onSubmit,
  title,
  message,
  submitText,
  blockedIcon = "ri-lock-password-line",
  submitIcon,
  secure,
  secure_phrase,
  textInput,
  textPlaceholder,
  initialText,
  loading,
  regex = null,
  children = null,
  secondaryText,
  secondaryIcon,
  secondaryOnSubmit,
  showCloseButton = true,
  disableScroll = true,
  hideButtons = false,
}) => {
  const [confirm, setConfirm] = React.useState(false);
  const [text, setText] = React.useState("");
  const [mouseDownStartedOutside, setMouseDownStartedOutside] = useState(false); // Used to detect if the mouse down event started outside the modal content

  //console.log(props)
  useEffect(() => {
    //disable scroll in html tag
    if (disableScroll) {
      if (show) {
        document.getElementsByTagName("html")[0].style.overflow = "hidden";
      } else {
        document.getElementsByTagName("html")[0].style.overflow = "auto";
      }
    }
    if (!secure) {
      setConfirm(true);
    } else {
      setConfirm(false);
    }

    if (!secure && (regex == null || regex == "")) {
      setConfirm(true);
    } else {
      if (regex == null || regex == "") {
        return;
      } else {
        let re = new RegExp(regex);
        if (re.test(initialText)) {
          setConfirm(true);
          return;
        }
      }

      setConfirm(false);
    }
  }, [secure, show]);

  useEffect(() => {
    setText(initialText);
  }, [initialText]);

  const validateTextInput = (e) => {
    setText(e.target.value);

    if (!regex || regex == "") {
      return true;
    } else {
      let re = new RegExp(regex);

      if (re.test(e.target.value)) {
        setConfirm(true);
      } else {
        setConfirm(false);
      }
    }
  };

  const handleMouseDown = (e) => {
    setMouseDownStartedOutside(e.target.classList.contains("winkel-popup"));
  };

  const handleMouseUp = (e) => {
    if (
      mouseDownStartedOutside &&
      e.target.classList.contains("winkel-popup")
    ) {
      onClose();
    }
  };

  return (
    <>
      <div
        className={show ? "winkel-popup show" : "winkel-popup"}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
      >
        <div
          className="winkel-popup-wrapper"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="winkel-popup-container">
            <div className="winkel-popup-content">
              <div className="winkel-popup-message byte-blitz-animated byte-blitz-bounce-in">
                <i
                  className="ri-close-line winkel-modal-close-button"
                  onClick={() => onClose()}
                />

                <h6>{title}</h6>
                <p style={{ fontSize: "15px" }}>{message}</p>

                {secure && (
                  <Row className="winkel-popup-input">
                    <hr />
                    <p className="gap-2">
                      <i
                        className="ri-lock-password-line"
                        style={{ marginRight: "5px" }}
                      ></i>
                      Um fortzufahren, gib bitte{" "}
                      <strong>{String(secure_phrase).trim()}</strong> in die
                      unten stehende Text-Box ein
                    </p>
                    <input
                      onChange={(e) =>
                        setConfirm(e.target.value === secure_phrase)
                      }
                      type="text"
                      className="winkel-popup-input"
                      placeholder=""
                    />
                  </Row>
                )}

                {textInput && (
                  <Row className="winkel-popup-input">
                    <input
                      onChange={(e) => validateTextInput(e)}
                      type="text"
                      className="winkel-popup-input"
                      placeholder={textPlaceholder}
                      value={text}
                    />
                  </Row>
                )}

                {children && (
                  <div className="dynamic-modal-content">{children}</div>
                )}


                {loading && (
                  <div className="winkel-popup-loading">
                    <span class="loader"></span>
                  </div>
                )}

                {!hideButtons && !loading && (
                  <>
                    <div className="winkel-popup-buttons">
                      {showCloseButton && (
                        <a
                          className="winkel-popup-button abort"
                          onClick={() => onClose()}
                        >
                          Abbrechen
                        </a>
                      )}
                      {secondaryText && (
                        <a
                          className="winkel-popup-button abort"
                          onClick={() => secondaryOnSubmit()}
                        >
                          {secondaryText} <i className={secondaryIcon}></i>
                        </a>
                      )}
                      {confirm ? (
                        <a
                          className="winkel-popup-button confirm"
                          onClick={() => onSubmit(text)}
                        >
                          {submitText ? submitText : "Fortfahren"}{" "}
                          <i className={submitIcon}></i>
                        </a>
                      ) : (
                        <a className="winkel-popup-button confirm disabled">
                          {submitText} <i className={blockedIcon}></i>
                        </a>
                      )}
                    </div>{" "}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WinkelModal;
