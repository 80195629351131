import { useEffect, useRef, useState } from "react";
import {
  Modal,
  Button,
  Input,
  TimePicker,
  DatePicker,
  Column,
  Table,
  Lookup,
  Textarea,
  MultiSelect,
  Picklist,
  Option,
  GoogleAddressLookup,
  CheckboxToggle,
  Badge,
  HelpText,
  DateTimePicker,
  ButtonGroupPicker,
  ButtonOption,
} from "react-rainbow-components";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import APIHandler from "../../handler/APIHandler";
import { Col, Row } from "reactstrap";
import { getDistance } from "geolib";
import WinkelModal from "../../components/ui/modal/winkelModal";
import MapComponent from "../calender/MapComponent";
import { toast } from "react-toastify";
import moment from "moment";

const styles = {
  input: {
    marginTop: 24,
  },
  inputColumn: {
    width: "100%",
    marginTop: 24,
  },
};

const CreateEventModal = ({ showModal, onClose, lead, onDeny, slot, onEventCreated }) => {
  useEffect(() => {
    if (!showModal) return;
    if (!reasonList.current) return;

    reasonList.current.focus();
    //emulate click
    setTimeout(() => {
      reasonList.current.click();
    }, 300);
  }, [showModal]);

  const reasonList = useRef(null);

  const [reason, setReason] = useState(null);
  const [zoom, setZoom] = useState(8);
  const [eventType, setEventType] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setZoom(20);

    console.log("slot", slot);

    if (slot?.title.includes("Außendienst")) {
      setEventType("vorort");
      console.log("Setting event type to vorort");
    } else {
      setEventType("video");
      console.log("Setting event type to video");
    }
  }, [lead]);

  useEffect(() => {
    console.log("Type", eventType);
  }, [eventType]);

  const acceptLead = async () => {


    setLoading(true);

    console.log("Accepting lead", lead, slot);

    //delte slot
    let deleteSlot = await APIHandler.Calendar.deleteSlot(
      slot.user.id,
      slot.event_id
    );

    console.log("Deleted slot", deleteSlot);

    //create event
    let event = await APIHandler.Calendar.createEvent(slot, lead, eventType);

    if (event.success === false) {
      setLoading(false);
      toast.error("Fehler beim Erstellen des Events");
      return;
    }

    //create hero project
    let book = await APIHandler.Leads.book(lead);

    console.log("Booked", book);

    //email & sms confirmation

    toast.success("Lead erfolgreich angenommen und Termin gebucht");
    onEventCreated();
    setLoading(false);
  };

  if (!lead || !slot) return null;

  return (
    <WinkelModal
      secure={false}
      show={showModal}
      title="Lead annehmen"
      regex={null}
      loading={loading}
      submitIcon="ri-calendar-check-line"
      submitText="Termin buchen & Bestätigung senden"
      onClose={onClose}
      onSubmit={() => {
        acceptLead();
      }}
      children={
        <>
          <Row>
            <Col md={12}>
              <MapComponent
                shownWaypoints={[
                  {
                    lat: lead.latitude,
                    lng: lead.longitude,
                  },
                ]}
                defZoom={zoom}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: "18px" }}>
            <Col md={6}>
              <span className="d-flex gap-2">
                <i class="ri-id-card-line"></i>
                {lead?.anrede} {lead?.vorname} {lead?.nachname}
              </span>
              <span className="d-flex gap-2">
                <i class="ri-phone-line"></i>
                {lead?.tel}
              </span>
              <span className="d-flex gap-2">
                <i class="ri-mail-line"></i>
                {lead?.email}
              </span>
              <span className="d-flex gap-2">
                <i class="ri-map-pin-line"></i>
                {lead?.straße} {lead?.hausnummer}, {lead?.plz} {lead?.ort}
              </span>
              <button className="cnt-edit-btn gap-2 d-flex">
                <i class="ri-edit-line"></i>Kontaktdaten bearbeiten
              </button>
            </Col>

            <Col md={6}>
              <span className="d-flex gap-2">
                <img
                  alt="people"
                  class="closer-avatar"
                  style={{ width: "20px", height: "20px" }}
                  src="https://api.dicebear.com/8.x/pixel-art/svg?seed=ralf52735@gmail.com"
                />
                {slot?.user.vorname} {slot?.user.nachname}
              </span>{" "}
              <input
                type="datetime-local"
                value={moment(slot?.start).format("YYYY-MM-DDTHH:mm")}
              />
              <ButtonGroupPicker
                id="button-group-picker-component-1"
                value={eventType}
                onChange={(value) => setEventType(value)}
                borderRadius="semi-square"
                style={{ alignItems: "flex-start", marginTop: "10px" }}
                name="filter"
                size="small"
              >
                <ButtonOption label="Vor-Ort" name="vorort" />
                <ButtonOption label="Videocall" name="video" />
              </ButtonGroupPicker>
            </Col>
          </Row>
        </>
      }
    />
  );
};

export default CreateEventModal;
