import { useEffect, useState } from "react";
import { Button, CheckboxGroup, Column, GMap, Input, Option, Picklist, Textarea, WeeklyCalendar } from "react-rainbow-components";
import APIHandler from "../../handler/APIHandler";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import moment from "moment";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "./calendar.scss";
import { Col, Row } from "reactstrap";
import MapComponent from "./MapComponent";
import "moment/locale/de";
import { Calendar, momentLocalizer } from "react-big-calendar";
import CalendarEvent from "./CalendarEvent";
import CustomEventWrapper from "./CalendarEventWrapper";
import { Table } from "react-rainbow-components";
import LeadNotes from "../../components/ui/LeadsNotes";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "animate.css";
import AddCustomerModal from "../customers/AddCustomerModal";
import WinkelModal from "../../components/ui/modal/winkelModal";
import LeadDeniedModal from "../customers/LeadDeniedModal";
import CreateEventModal from "../customers/CreateEventModal";
import Skeleton from 'react-loading-skeleton'
/*
let googleRouteWaypoints = [
  { location: { lat: , lng: 2.3522 } },
  { location: { lat: 48.8566, lng: 2.3522 } },
];
*/
const DragAndDropCalendar = withDragAndDrop(Calendar);
moment.locale("de");
const localizer = momentLocalizer(moment); // or globalizeLocalizer

const winkel_address = {
  lat: 51.500972,
  lng: 7.570575,
  icon: "https://www.winkelenergie.de/images/master-1-favicon.ico?crc=4068355874",
  lead: {
    vorname: "Winkel",
    nachname: "Energie",
    straße: "Hans-Böckler-Straße",
    hausnummer: "9",
    plz: "40476",
    ort: "Düsseldorf",
    tel: "+49 0231 53 41 22 68",
    email: "info@winkelenergie.de"

  }

};

const Calender = () => {
  const [events, setEvents] = useState(null);
  const [allEvents, setAllEvents] = useState([]);
  const [availableClosers, setAvailableClosers] = useState([]);
  const [shownWaypoints, setShownWaypoints] = useState([]); // [ { lat: 0, lng: 0 }, { lat: 0, lng: 0 }
  const [leadData, setLeadData] = useState(null);

  const [selectedLead, setSelectedLead] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);

  const [showNewCustomerModal, setShowNewCustomerModal] = useState(false);
  const [showLeadDeniedModal, setShowLeadDeniedModal] = useState(false);
  const [showCreateEventModal, setShowCreateEventModal] = useState(false);



  const [activeEditLead, setActiveEditLead] = useState(null);

  const lightenColor = (color, percent) => {
    let num = parseInt(color.replace("#", ""), 16),
      amt = Math.round(2.55 * percent),
      R = (num >> 16) + amt,
      G = ((num >> 8) & 0x00ff) + amt,
      B = (num & 0x0000ff) + amt;
    return (
      "#" +
      (
        0x1000000 +
        (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
        (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 +
        (B < 255 ? (B < 1 ? 0 : B) : 255)
      )
        .toString(16)
        .slice(1)
    );
  };

  const eventStyleGetter = (event, start, end, isSelected) => {
    let backgroundColor = "red";

    if (event.type == "slot") {
      backgroundColor = event.user.system_farbe;
    } else {
      backgroundColor = lightenColor(event.user.system_farbe, 20);
    }

    let color = "black";

    //calculate if foreground color should be black or white
    let r = parseInt(backgroundColor.substr(1, 2), 16);
    let g = parseInt(backgroundColor.substr(3, 2), 16);
    let b = parseInt(backgroundColor.substr(5, 2), 16);

    let brightness = Math.sqrt(
      r * r * 0.299 + g * g * 0.587 + b * b * 0.114
    );

    if (brightness > 127.5) {
      color = "black";
    } else {
      color = "white";
    }

    let style = {
      backgroundColor: backgroundColor,
      borderRadius: "4px",
      opacity: 0.8,
      fontSize: "14px",
      fontWeight: "300",
      color: color,
      display: "block",
    };
    return {
      style: style,
    };
  };

  useEffect(() => {
    let user = localStorage.getItem("user");
    if (user == null) {
      window.location.href = "/login";
    }
    loadEvents();
  }, []);

  const loadEvents = async () => {

    setEvents(null);
    setAvailableClosers([]);

    let eventData = await APIHandler.Calendar.getCloserCalendar();

    let calendarData = [];

    eventData.forEach((closer) => {
      calendarData = calendarData.concat(
        closer.closerObj.events.map((event) => {
          setAvailableClosers((prev) => {
            if (prev.includes(closer.closerObj.user)) {
              return prev;
            }

            let _closer = closer.closerObj.user;
            _closer.showEvents = true;
            _closer.showSlots = true;

            return prev.concat(_closer);
          });

          return {
            title: event.summary ? event.summary : "Kein Titel",
            start: new Date(event.start.dateTime),
            end: new Date(event.end.dateTime),
            type: "event",
            user: closer.closerObj.user,
            event_id: event.id,
            description: event.description,
            winkelApp: event.winkelApp,
            canceled: event.attendees?.find((attendee) => attendee.responseStatus == "declined") ? true : false
          };
        })
      );

      calendarData = calendarData.concat(
        closer.closerObj.slotEvents.map((slot) => {
          return {
            title: slot.summary ? slot.summary : "Kein Titel",
            start: new Date(slot.start.dateTime),
            end: new Date(slot.end.dateTime),
            type: "slot",
            user: closer.closerObj.user,
            event_id: slot.id,
          };
        })
      );
    });

    console.log(calendarData);
    setEvents(calendarData);
    setAllEvents(calendarData);

    APIHandler.Customers.getAllLeads().then((data) => {
      setLeadData(data);

      let waypoints = [];
      data.forEach((lead) => {
        console.log(lead);
        if (lead.latitude == null || lead.longitude == null) {
          return;
        }
        waypoints.push({
          lead: lead,
          lat: lead.latitude,
          lng: lead.longitude,
        });
      });


      waypoints.push(winkel_address);

      setShownWaypoints(waypoints);
    });



  };

  const updateLeads = () => {
    APIHandler.Customers.getAllLeads().then((data) => {
      setLeadData(data);

      let waypoints = [];
      data.forEach((lead) => {
        console.log(lead);
        if (lead.latitude == null || lead.longitude == null) {
          return;
        }
        waypoints.push({
          lead: lead,
          lat: lead.latitude,
          lng: lead.longitude,
        }, winkel_address);
      });

      setShownWaypoints(waypoints);
    });
  };

  const filterEvents = () => {
    let showEvents = allEvents;

    console.log(availableClosers);

    availableClosers.forEach((closer) => {
      if (closer.showEvents == false) {
        showEvents = showEvents.filter(
          (event) => event.user.id != closer.id || event.type != "event"
        );
      }

      if (closer.showSlots == false) {
        showEvents = showEvents.filter(
          (event) => event.user.id != closer.id || event.type != "slot"
        );
      }
    });

    console.log(showEvents);
    setEvents(showEvents);
  };

  return (
    <div style={{
      marginTop: "25px"
    }}>
      <ToastContainer />
      {selectedLead && <div className="backdrop" />}

      <Row style={{ marginBottom: "15px" }}>
        <Col lg="3">
          <CloserViewControl
            closers={availableClosers}
            changeFilter={(closer, type, checked) => {
              let closers = availableClosers;

              console.log("Change filter", closer, type, checked);


              let newCloser = closers.find((c) => c.id == closer.id);
              if (type == "slots") {
                newCloser.showSlots = checked;
              } else {
                newCloser.showEvents = checked;
              }
              setAvailableClosers(closers);

              filterEvents();
            }}

            changeAllFilters={(type, checked) => {
              let closers = availableClosers;


              let allChecked = closers.every((closer) => {
                if (type == "slots") {
                  return closer.showSlots == true;
                } else {
                  return closer.showEvents == true;
                }
              });


              if (allChecked) {
                closers.forEach(closer => {
                  if (type == "slots") {
                    closer.showSlots = false;
                  } else {
                    closer.showEvents = false;
                  }
                })
              } else {
                closers.forEach(closer => {
                  if (type == "slots") {
                    closer.showSlots = true;
                  } else {
                    closer.showEvents = true;
                  }
                })
              }



              setAvailableClosers(closers);

              filterEvents();
            }
            }
          />
        </Col>

        <Col lg="9">
          <MapComponent shownWaypoints={shownWaypoints} />
        </Col>
      </Row>
      <Row>
        <Col lg="3">
          <div className="lead-container">
            <Row>
              <div className="leads-header">
                <h1>Leads</h1>
                <button onClick={() => setShowNewCustomerModal(true)} className="add-lead-btn" aria-label="Add new lead"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-plus h-4 w-4"><path d="M5 12h14"></path><path d="M12 5v14"></path></svg></button>
              </div>
            </Row>
            <div className="lead-entries">

              {
                !leadData && (
                  <Skeleton count={3} height={"50px"} />
                )
              }

              {leadData && leadData.length == 0 && (
                <div className="no-leads">
                  <p style={{ fontWeight: "300" }} className="d-flex gap-2"><i class="ri-filter-off-line"></i>Es sind keine Leads verfügbar</p>
                </div>
              )}

              {leadData?.map((lead) => {
                return (
                  <LeadEntry
                    onNe={() => {
                      APIHandler.Customers.addHistorie({
                        kunden_id: lead.id,
                        typ: 2,
                        mitarbeiter_id: 1,
                        info: "NE",
                        ne_count: lead.ne + 1,
                      }).then((data) => {
                        console.log(data);

                        if (data.success == false && data.tooManyNE) {
                          toast.error(
                            "Zu viele NEs für diesen Lead, Lead abgelehnt..."
                          );
                          return;
                        }
                      });

                      // Update lead data

                      let newLeadData = leadData.map((leadEntry) => {
                        if (leadEntry.id == lead.id) {
                          leadEntry.ne += 1;
                        }

                        if (leadEntry.ne >= 10) {
                          leadEntry.status = "ABGELEHNT";
                          return leadEntry;
                        }

                        return leadEntry;
                      });

                      setLeadData(newLeadData);
                      updateLeads();
                    }}
                    onAccept={() => {
                      console.log("Selected lead", lead);
                      if (selectedLead != null && selectedLead.id == lead.id) {
                        setSelectedLead(null);
                        return;
                      }

                      setSelectedLead(lead);
                    }}
                    onDeny={() => {
                      setSelectedLead(lead);
                      setShowLeadDeniedModal(true);
                    }}
                    selected={selectedLead?.id == lead.id ? true : false}
                    lead={lead}


                    onEditStart={() => {

                      if (activeEditLead?.id == lead.id) {
                        setActiveEditLead(null);
                        return;
                      }

                      setActiveEditLead(lead);
                    }}


                    editorActive={activeEditLead?.id == lead.id ? true : false}
                  />
                );
              })}
            </div>
          </div>
        </Col>
        <Col lg="9">
          <DragAndDropCalendar
            events={events ? events : []}
            localizer={localizer}
            className="calendar-setting"
            startAccessor="start"
            culture="de"
            endAccessor="end"
            min={new Date(1970, 1, 1, 5, 0, 0)} // Start time 06:00 AM
            max={new Date(1970, 1, 1, 22, 0, 0)} // End time 10:00 PM
            view="week"
            views={["week"]}
            onSelectEvent={(event) => {
              if (event.type == "slot") {
                console.log("Selected slot", event);
                setSelectedSlot(event);

                if (selectedLead) {
                  setShowCreateEventModal(true);
                }
              } else {
                console.log("Selected event", event);
              }
            }}
            components={{
              event: CalendarEvent,
            }}
            eventPropGetter={eventStyleGetter}
          />
        </Col>
      </Row>
      <AddCustomerModal
        showModal={showNewCustomerModal}
        onClose={(newEntry) => {
          setShowNewCustomerModal(false);

          if (newEntry) {
            updateLeads();
          }
        }}
      />

      <LeadDeniedModal
        showModal={showLeadDeniedModal}
        onClose={() => {
          setSelectedLead(null);
          setShowLeadDeniedModal(false);
        }}
        onDeny={(lead) => {
          console.log("Deny lead", lead);
          setLeadData(leadData.filter((leadEntry) => leadEntry.id != lead.id));
        }}
        lead={selectedLead}
      />

      <CreateEventModal
        showModal={showCreateEventModal}
        onClose={() => {
          setShowCreateEventModal(false);
          console.log("Closed create event modal");
          setSelectedLead(null);

        }}
        onEventCreated={(event) => {
          updateLeads();
          loadEvents();
          setSelectedLead(null);
          setShowCreateEventModal(false);

        }}

        lead={selectedLead}
        slot={selectedSlot}
      />
    </div>
  );
};

const LeadEntry = ({ lead, selected, onAccept, onDeny, onNe, onEditStart, editorActive }) => {
  const [showNotes, setShowNotes] = useState(true);
  const [expanded, setExpanded] = useState(false);

  const [heroDatenVollstaendig, setHeroDatenVollstaendig] = useState(false);

  const leads = [
    { id: 1, name: 'Jung', status: 'INTERESSENT' },
    { id: 2, name: 'Jung', status: 'INTERESSENT' },
    { id: 3, name: 'Jung', status: 'LEAD', score: 0 },
  ];

  return (
    <li key={lead.id} className="lead__item lead-entry"

      onClick={(e) => {
        e.stopPropagation();
        console.log("Clicked", lead);

        if (lead.status == "LEAD") {
          setExpanded(!expanded);
        }

        if (lead.status == "INTERESSENT") {
          onEditStart();
        }
      }}

    >
      <div className="lead-info">
        <span className="lead-name">{lead.nachname}</span>
        <span className={`lead-status ${lead.status.toLowerCase()}`}>{lead.status}</span>
      </div>
      <div className="lead-actions">
        {lead.status === 'LEAD' && (
          <span className="lead-score" onClick={(e) => {
            e.stopPropagation();
            onNe();
          }}>{lead.ne}</span>
        )}
        {(lead.status == "INTERESSENT") && (
          (lead.hero_daten_vollstaendig || heroDatenVollstaendig) ? <button className="action-btn info-btn hero-ok" aria-label="More information"><i className="ri-information-line"></i></button> : <button className="action-btn info-btn hero-warning" aria-label="More information"><i className="ri-information-line"></i></button>
        )}

        {lead.status === 'LEAD' && (
          <>
            <button className="action-btn approve-btn" aria-label="Approve lead" onClick={(e) => {
              e.stopPropagation();
              onAccept();
            }}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-check h-4 w-4"><path d="M20 6 9 17l-5-5"></path></svg></button>
            <button className="action-btn reject-btn" aria-label="Reject lead" onClick={(e) => {
              e.stopPropagation();
              onDeny();
            }}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-x h-4 w-4"><path d="M18 6 6 18"></path><path d="m6 6 12 12"></path></svg></button>
          </>
        )}
      </div>

      {(lead.status == "INTERESSENT") && (
        <LeadHeroSlider onSync={() => {
          setHeroDatenVollstaendig(true);
        }} lead={lead} isOpen={editorActive} />
      )}
    </li>
  );

  return (
    <div
      className={selected ? "lead-entry selected" : "lead-entry"}
      onClick={(e) => {
        e.stopPropagation();

        if (lead.status == "LEAD") {
          setExpanded(!expanded);
        }

        if (lead.status == "INTERESSENT") {
          onEditStart();
        }
      }}
    >
      <h5 style={{ marginBottom: 0 }} className="d-flex gap-2">
        <i title={lead.tel} class="ri-phone-fill"></i>  {lead.nachname}
        <span className="lead-badge">{lead.status}</span>

        {(lead.status == "INTERESSENT") && (
          <div className="button-container">
            {
              (lead.hero_daten_vollstaendig || heroDatenVollstaendig) ? <i class="ri-information-line hero-ok"></i> : <i class="ri-information-line hero-warning"></i>
            }
          </div>
        )}


        {lead.status == "LEAD" && (
          <div className="button-container">
            <div
              title={lead.ne + "x2 NE"}
              className="button ne"

            >
              {lead.ne}
            </div>

            <div
              title="Annehmen"
              onClick={(e) => {
                e.stopPropagation();
                onAccept();
              }}
              className="button check"
            >
              <i class="ri-check-line"></i>
            </div>

            <div
              title="Ablehnen"
              onClick={(e) => {
                e.stopPropagation();
                onDeny();
              }}
              className="button denied"
            >
              <i class="ri-prohibited-line"></i>
            </div>
          </div>
        )}
      </h5>

      {expanded && (
        <>
          <span style={{ marginTop: "5px" }}>
            <i class="ri-map-pin-line"></i> {lead.straße} {lead.hausnummer},{" "}
            {lead.plz} {lead.ort}
          </span>
          <span style={{ marginTop: "5px" }}>
            <i class="ri-calendar-2-line"></i>{" "}
            {moment(lead.lead_eingangs_datum).format("DD.MM.YYYY")} Facebook
          </span>

          <LeadNotes lead={lead} />
        </>
      )}


    </div>
  );
};


const LeadHeroSlider = ({ lead, isOpen, onSync }) => {

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);


  const updateData = (newData) => {
    setData(newData);
    console.log("Updated data", newData);
    syncData(newData);
  };


  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      let heroResp = await APIHandler.Customers.getHeroData(lead.id);
      setLoading(false);
      if (heroResp.success == false) {
        return;
      }
      setData(heroResp.heroData);
    }

    console.log("Lead", lead);

    if (lead && isOpen) {
      console.log("Fetching data");
      fetchData();
    }
  }, [isOpen]);


  if (!isOpen) {
    return null;
  }

  async function sendToHero() {
    setLoading(true);
    let response = await APIHandler.Customers.sendDataToHero(lead.id);
    if (!response.success) {
      toast.error("Fehler beim Senden der Daten an Hero");
    }
    setLoading(false);
  }


  async function syncData(newData) {


    if (!newData) {
      newData = data;
    }
    console.log("Sync data");
    setLoading(true);
    console.log(newData);
    let response = await APIHandler.Customers.syncHeroData(lead.id, newData);
    if (!response.success) {
      toast.error("Fehler beim Synchronisieren der Daten");
    }
    setLoading(false);
    onSync();
  }

  return (
    <div className={"hero-slider " + (loading && "loading")}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >

      <div className="hero-wrapper">
        {loading && (
          <span class="loader"></span>
        )}


        <Row>
          <Col md={12}>
            <h5>{lead.vorname} {lead.nachname}</h5>

          </Col>
        </Row>

        <Row>
          <span>Was spricht dafür eine PV Anlage zu installieren?</span>
          <CheckboxGroup
            options={[
              { value: "1", label: "Energiekosten sparen" },
              { value: "2", label: "Umwelt schonen" },
              { value: "3", label: "Unabhängigkeit von Energieversorgern" },
              { value: "4", label: "Wertsteigerung der Immobilie" },
              { value: "5", label: "Förderung durch den Staat" },
            ]}
            value={data?.reasons}

            onChange={(value) => {
              console.log(value);
              updateData({ ...data, reasons: value });
            }}
          />
        </Row>
        <Row>
          <Picklist
            labelAlignment="left"
            label="Liegt bereits ein Angebot vor?"
            value={data?.offer}
            onChange={(value) => {
              console.log(value);
              updateData({ ...data, offer: value });
            }}
            required={false}
            borderRadius="semi-square"
          >
            <Option
              name="offer" label="Ja" />

            <Option
              name="no-offer" label="Nein" />
          </Picklist>
        </Row>
        <Row>
          <Input
            labelAlignment="left"
            style={{ zIndex: 5 }}
            label="Verbrauch in kWh"
            type="number"
            borderRadius="semi-square"
            placeholder="Verbrauch in kWh"
            value={data?.verbrauch}
            onChange={(e) => {
              console.log(e.target.value);
              updateData({ ...data, verbrauch: e.target.value });
            }}
          />
        </Row>
        <Row>
          <Picklist
            labelAlignment="left"
            label="Heizt primär mit"
            onChange={(value) => {
              console.log(value);
              updateData({ ...data, heizung: value });
            }}
            value={data?.heizung}
            required={false}
            borderRadius="semi-square"
          >
            <Option
              name="heat-pump" label="Wärmepumpe" />
            <Option
              name="gas" label="Gas" />

            <Option
              name="oil" label="Öl" />
          </Picklist>
        </Row>
        <Row>
          <Picklist
            labelAlignment="left"
            label="Heizt sein Brauchwasser mit"
            value={data?.brauchwasser}
            onChange={(value) => {
              updateData({ ...data, brauchwasser: value });
              console.log(value);
            }}
            required={false}
            borderRadius="semi-square"
          >
            <Option
              name="heat-pump" label="Wärmepumpe" />
            <Option
              name="gas" label="Gas" />

            <Option
              name="oil" label="Öl" />
          </Picklist>
        </Row>
        <Row>
          <Picklist
            labelAlignment="left"
            label="Entscheidet er alleine?"
            value={data?.entscheidetAlleine}
            onChange={(value) => {
              console.log(value);
              updateData({ ...data, entscheidetAlleine: value });
            }}
            required={false}
            borderRadius="semi-square"
          >
            <Option
              name="offer" label="Ja" />

            <Option
              name="no-offer" label="Nein" />
          </Picklist>
        </Row>
        <Row>
          <Picklist
            labelAlignment="left"
            label="Zoom bereits genutzt?"
            value={data?.zoom}
            onChange={(value) => {
              console.log(value);
              updateData({ ...data, zoom: value });
            }}
            required={false}
            borderRadius="semi-square"
          >
            <Option
              name="offer" label="Ja" />

            <Option
              name="no-offer" label="Nein" />
          </Picklist>
        </Row>
        <Row>
          <Picklist
            labelAlignment="left"
            label="Bereits für eine PV Anlage entschieden?"
            value={data?.pv}
            onChange={(value) => {
              console.log(value);
              updateData({ ...data, pv: value });
            }}
            required={false}
            borderRadius="semi-square"
          >
            <Option
              name="offer" label="Ja -> Winkel verkaufen" />

            <Option
              name="no-offer" label="Nein" />
          </Picklist>
        </Row>
        <Row>
          <Input
            labelAlignment="left"
            style={{ zIndex: 5 }}
            label="Geschätztes Potenzial in kWp"
            value={data?.potenzial}
            onChange={(e) => {
              console.log(e.target.value);
              updateData({ ...data, potenzial: e.target.value });
            }}
            type="number"
            borderRadius="semi-square"
            placeholder="Geschätztes Potenzial in kWp"
          />
        </Row>
        <Row>
          <Textarea
            labelAlignment="left"
            style={{ zIndex: 5 }}
            label="Anmerkungen"
            placeholder="Anmerkungen"
            rows={4}
            borderRadius="semi-square"
            value={data?.notes}
            onChange={(e) => {
              console.log(e.target.value);
              updateData({ ...data, notes: e.target.value });
            }}
          />
        </Row>
        <Row>
          <Button
            className="hero-sync gap-2"
            style={{ marginTop: "10px" }}
            label="An Hero senden"
            onClick={() => {
              sendToHero();
            }}
            variant="brand"
            borderRadius="semi-square"
          >
            <img style={{
              width: "22px", height: "20px"
            }} src="https://images.basaas.com/uploads/app/61f908db6603190011200987/19235b6e-0350-4e32-a7eb-1ad97a70cc77-smart-business-cloud-footer-hero.png?width=250" alt="Winkel Energie" />
            <span> An Hero senden</span>
          </Button>
        </Row>
      </div>
    </div>
  );
};



const CloserViewControl = ({ closers, changeFilter, changeAllFilters }) => {
  const [selectedCloser, setSelectedCloser] = useState(null);


  const transparentColor = (hex, alpha) => {
    let r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }


  let allEvents = closers.every((closer) => {
    return closer.showEvents == true;
  });

  let allSlots = closers.every((closer) => {
    return closer.showSlots == true;
  });

  return (
    <section className="closer-list">
      <div className="header">
        <h2>Alle Closer</h2>
        <div>
          <span onClick={(e) => changeAllFilters("slots", true)} style={{ '--closer-color': "#171718" }} className={`status-badge ${allSlots ? 'active' : ''}`}>
            Slots
            {allSlots && <span className="check-icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-check h-3 w-3"><path d="M20 6 9 17l-5-5"></path></svg></span>}
          </span>
          <span onClick={(e) => changeAllFilters("events", true)} style={{ '--closer-color': "#171718" }} className={`status-badge ${allEvents ? 'active' : ''}`}>
            Events
            {allEvents && <span className="check-icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-check h-3 w-3"><path d="M20 6 9 17l-5-5"></path></svg></span>}
          </span>
        </div>
      </div>
      <ul className="closer-container">
        {closers.map((closer) => (
          <li key={closer.id} className="closer-item" style={{ '--closer-color': closer.system_farbe, backgroundColor: transparentColor(closer.system_farbe, 0.2) }}>
            <div className="closer-info">
              <div className="closer-avatar">
                <span>{closer.kuerzel}</span>
              </div>
              <span className="closer-name">{closer.nachname}</span>
            </div>
            <div className="closer-status">
              <span onClick={(e) => changeFilter(closer, "slots", !closer.showSlots)} className={`status-badge ${closer.showSlots ? 'active' : ''}`}>
                Slots
                {closer.showSlots && <span className="check-icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-check h-3 w-3"><path d="M20 6 9 17l-5-5"></path></svg></span>}
              </span>
              <span onClick={(e) => changeFilter(closer, "events", !closer.showEvents)} className={`status-badge ${closer.showEvents ? 'active' : ''}`}>
                Events
                {closer.showEvents && <span className="check-icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-check h-3 w-3"><path d="M20 6 9 17l-5-5"></path></svg></span>}
              </span>
            </div>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default Calender;
